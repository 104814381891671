import React, { useEffect, useState } from 'react';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { Checkbox } from 'primereact/checkbox';

import { loginLuca, getReceiptListAndDetails, sendReceiptsOnLuca } from '../module/luca/utils/lucaRequest';
import { translateDocType, documentData, docName, itemNo, checkAmountAndCredit, rowIsTrue, wrongRow } from './eDefterLuca/utils/controller';
import { getCompany, isOther, getErrorReceiptCount, getReceiptDetailsDocType, getPeriods } from './eDefterLuca/utils/utils';
import receiptCode from '../../assets/data/receiptCode.json';
import { getAllWebServicesByModuleId } from '../../service/request/moduleRequest';
import SendReceiptDialog from './eDefterLuca/components/SendReceiptDialog';
import AllReceiptUpdateDialog from './eDefterLuca/components/AllReceiptsUpdateDialog';
import OneReceiptUpdateDialog from './eDefterLuca/components/OneReceiptsUpdateDialog';

import { formatAmount, formatDate, HeaderProgram, localeDate, mapTipNoToLabel, EditButtonTemplate, selectLastThreeMonths, selectLastTwoMonths, PortalHeader } from './utils/utils';
import { InputSwitch } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';
import Loading from '../Loading';
import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';
import { Dialog } from 'primereact/dialog';

export default function ReceiptList() {
    const [receipts, setReceipts] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const { selectedCompany } = useSelector((state) => state.userCompany);
    const [startDate, setStartDate] = useState(selectLastThreeMonths());
    const [endDate, setEndDate] = useState(selectLastTwoMonths());
    const [loading, setLoading] = useState(false);
    const [selectedCompanyCode, setSelectedCompanyCode] = useState(null);
    const [periodVisible, setPeriodVisible] = useState(false);

    const [selectedReceipt, setSelectedReceipt] = useState(null);

    const [oneEditDialog, setOneEditDialog] = useState(false);
    const [allEditDialog, setAllEditDialog] = useState(false);
    const [sendReceiptsDialog, setSendReceiptsDialog] = useState(false);

    const [jSessionId, setJSessionId] = useState(null);
    const [wMonId, setWMonId] = useState(null);

    const [pending, setPending] = useState(false);
    const [errorReceiptsCount, setErrorReceiptsCount] = useState(0);
    const [checked, setChecked] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(selectLastThreeMonths());
    const [checkErrorReceipts, setCheckErrorReceipts] = useState(false);

    addLocale('tr', localeDate);

    useEffect(() => {
        if (receipts.length !== 0) setErrorReceiptsCount(getErrorReceiptCount(receipts));
    }, [receipts]);

    useEffect(() => {
        if (errorReceiptsCount === 0) {
            setCheckErrorReceipts(false);
        }
    }, [errorReceiptsCount]);

    const getReceiptList = async () => {
        setLoading(true);
        let selectedModule = selectedCompany.modules.find((module) => module.title === 'E-Defter');
        const req = selectedModule.setting.settings.program.requirements;
        try {
            const res = await loginLuca(req[0].username, req[0].password, req[0].customerNumber);
            setJSessionId(res.data.data.jSessionId);
            setWMonId(res.data.data.wMonId);
            const company = selectedModule.setting.settings.program.lucaCompanies.filter((company) => company.companyCode === selectedCompanyCode)[0];

            const receiptListAndDetails = await getReceiptListAndDetails(res.data.data.jSessionId, res.data.data.wMonId, company.companyCode, company.periodId, formatDate(startDate, 'slash'), formatDate(endDate, 'slash'), company.belgeTurleri);

            setReceipts(receiptListAndDetails.data.data.filter((item) => item.receipt.fis_state === 1));

            setLoading(false);
            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fişler başarıyla getirildi.', life: 3000 });
            }, 100);
        } catch (error) {
            setLoading(false);
            setTimeout(() => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message, life: 3000 });
            }, 100);
            return;
        }
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    const handleMonthChange = (e) => {
        setSelectedMonth(e.value);
        setStartDate(new Date(e.value.getFullYear(), e.value.getMonth(), 1));
        setEndDate(new Date(e.value.getFullYear(), e.value.getMonth() + 1, 0));
    };

    const rowExpansionTemplate = (rowData) => {
        const receiptDetails = rowData.receipt.receiptDetails;
        return (
            <div className="p-3">
                <h5>Fiş Detayı</h5>
                <DataTable emptyMessage="Modül Bulunamadı" value={receiptDetails}>
                    <Column field="hesap_kodu" header="Hesap Kodu" style={{ minWidth: '12rem' }} sortable tooltip="Save" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}></Column>
                    <Column field="hesap_adi" header="Hesap Adı" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="borc" header="Borç" style={{ minWidth: '12rem' }} sortable body={(rowData) => checkAmountAndCredit(rowData.borc, rowData.alacak, 'borc')}></Column>
                    <Column field="alacak" header="Alacak" style={{ minWidth: '12rem' }} sortable body={(rowData) => checkAmountAndCredit(rowData.borc, rowData.alacak, 'alacak')}></Column>
                    <Column field="aciklama" header="Açıklama" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="evrak_no" header="Evrak No" style={{ minWidth: '12rem' }} sortable body={(rowData) => documentData(rowData.evrak_no, rowData.evrak_tarihi, 'evrak_no')}></Column>
                    <Column field="evrak_tarihi" header="Evrak Tarihi" style={{ minWidth: '12rem' }} sortable body={(rowData) => documentData(rowData.evrak_no, rowData.evrak_tarihi, 'evrak_tarihi')}></Column>
                    <Column field="belge_turu" header="Belge Türü" style={{ minWidth: '12rem' }} sortable body={(rowData) => getReceiptDetailsDocType(rowData.belge_turu)}></Column>
                    <Column field="odeme_yontemi" header="Ödeme Yöntemi" style={{ minWidth: '12rem' }} sortable></Column>
                </DataTable>
            </div>
        );
    };

    const check = (rowData) => {
        const rec = rowData.receipt;
        rec.belgesiz_fis = rec.belgesiz_fis === 1 ? 0 : 1;
        const recCopy = [...receipts];
        const index = recCopy.findIndex((r) => r.receipt.id === rec.id);
        recCopy[index].receipt = rec;

        setReceipts(recCopy);
    };

    const docTemplate = (rowData, title) => {
        const doc = rowData?.receipt?.receiptDetails[0];
        return documentData(doc?.evrak_no, doc?.evrak_tarihi, title, rowData?.receipt?.kaynak_belge_turu);
    };

    const handleHideOneEditDialog = () => {
        setOneEditDialog(false);
        setSelectedReceipt(null);
    };

    const handleUpdateOneReceipt = ({ selectedCode, selectedOtherCode, paymentType, docNo, docDate }) => {
        const rec = selectedReceipt[0].receipt;

        if (selectedCode) {
            rec.kaynak_belge_turu = receiptCode.find((item) => item.code === selectedCode).EdefterEnum || rec.kaynak_belge_turu;

            if (isOther(selectedCode)) {
                rec.kaynak_belge_turu_aciklama = receiptCode.find((item) => item.code === selectedOtherCode).content || rec.kaynak_belge_turu_aciklama;
            } else {
                rec.kaynak_belge_turu_aciklama = '';
            }
        }

        rec.receiptDetails.forEach((item) => {
            item.evrak_no = docNo || item.evrak_no;
            item.evrak_tarihi = formatDate(docDate, 'slash') || item.evrak_tarihi;
            item.odeme_yontemi = paymentType || item.odeme_yontemi;

            if (selectedOtherCode) {
                item.belge_turu = selectedOtherCode || item.belge_turu;
            } else {
                item.belge_turu = selectedCode || item.belge_turu;
            }
        });

        const recCopy = [...receipts];

        const index = recCopy.findIndex((r) => r.receipt.id === rec.id);

        recCopy[index].receipt = rec;

        setReceipts(recCopy);

        setOneEditDialog(false);
        setSelectedReceipt(null);
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fiş başarıyla güncellendi.', life: 3000 });
    };

    const handleUpdateAllReceipt = ({ selectedCode, selectedOtherCode, paymentType, docIsUpdate, noDocReceipt }) => {
        const recCopy = [...receipts];

        selectedReceipt.forEach((item) => {
            const rec = item.receipt;

            if (selectedCode) {
                rec.kaynak_belge_turu = receiptCode.find((item) => item.code === selectedCode).EdefterEnum || rec.kaynak_belge_turu;

                if (isOther(selectedCode)) {
                    rec.kaynak_belge_turu_aciklama = receiptCode.find((item) => item.code === selectedOtherCode).content || rec.kaynak_belge_turu_aciklama;
                } else {
                    rec.kaynak_belge_turu_aciklama = '';
                }
            }

            if (noDocReceipt) {
                rec.belgesiz_fis = 1;
            } else {
                rec.belgesiz_fis = 0;
            }

            rec.receiptDetails.forEach((item) => {
                if (docIsUpdate) {
                    item.evrak_no = rec.fis_no || item.evrak_no;
                    item.evrak_tarihi = rec.fis_tarihi || item.evrak_tarihi;
                }

                item.odeme_yontemi = paymentType || item.odeme_yontemi;
                if (selectedOtherCode) {
                    item.belge_turu = selectedOtherCode || item.belge_turu;
                } else {
                    item.belge_turu = selectedCode || item.belge_turu;
                }
            });

            const index = recCopy.findIndex((r) => r.receipt.id === rec.id);

            recCopy[index].receipt = rec;
        });

        setReceipts(recCopy);

        setAllEditDialog(false);
        setSelectedReceipt(null);
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fişler başarıyla güncellendi.', life: 3000 });
    };

    const periodChecker = async () => {
        if (getPeriods(selectedCompany, selectedCompanyCode) === startDate.getFullYear().toString()) {
            await getReceiptList();
        } else {
            setPeriodVisible(true);
        }
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div className="flex align-items-center gap-5">
                <h4 className="m-0">Fiş Listesi</h4>
                {receipts.length !== 0 && (
                    <div className="flex align-items-center gap-5">
                        <div className="m-0 p-badge p-overlay-badge p-badge-info">
                            Hatalı Fiş Sayısı <span className="p-badge p-badge-danger">{errorReceiptsCount}</span>
                        </div>
                        <div className=" flex align-items-end gap-2">
                            <label className="block font-normal">Hatalı Fişleri Listele</label>
                            <InputSwitch
                                checked={checkErrorReceipts}
                                onChange={(e) => {
                                    setCheckErrorReceipts(e.value);
                                    setSelectedReceipt(null);
                                }}
                                disabled={errorReceiptsCount === 0}
                            />
                        </div>
                        <div className="p-badge p-overlay-badge p-badge-success text-white">
                            Fiş Adedi <span className="p-badge p-badge-danger">{receipts.length}</span>
                        </div>
                    </div>
                )}
            </div>
            <div>
                {receipts.length !== 0 && (
                    <h6 className="mb-0 mr-6 p-badge p-overlay-badge p-badge-danger text-white h-auto w-auto">
                        Toplam Borç{' '}
                        {formatAmount(
                            receipts.reduce((acc, item) => acc + item.receipt.borc, 0),
                            'TRY'
                        )}
                    </h6>
                )}
                {receipts.length !== 0 && (
                    <h6 className="m-0 p-badge p-overlay-badge p-badge-success text-white h-auto w-auto">
                        Toplam Alacak{' '}
                        {formatAmount(
                            receipts.reduce((acc, item) => acc + item.receipt.alacak, 0),
                            'TRY'
                        )}
                    </h6>
                )}
            </div>
            <div>
                <EditButtonTemplate data={selectedReceipt} onEdit={() => setOneEditDialog(true)} onAllEdit={() => setAllEditDialog(true)} />
                <Button icon="pi pi-arrow-right" severity="success" className="mr-4 text-white" label="E-Defter Oluştur" onClick={() => setSendReceiptsDialog(true)} disabled={!(receipts.length > 0 && errorReceiptsCount === 0)} loading={pending} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );
    //Bakılacak
    const sendReceipts = async (enteredBy, vknTckn, startDate, endDate, directSendValue) => {
        let module = selectedCompany.modules.find((module) => module.title === 'E-Defter');
        const lucaCompany = module.setting.settings.program.lucaCompanies.filter((company) => company.companyCode === selectedCompanyCode)[0];
        const webServiceReq = lucaCompany.webService.requirements;

        const data = {
            jSessionId: jSessionId,
            wMonId: wMonId,
            periodId: lucaCompany.periodId,
            startDate: formatDate(startDate, 'dashReverse'),
            endDate: formatDate(endDate, 'dashReverse'),
            enteredBy: enteredBy,
            VknTckn: vknTckn,
            belgeTurleri: lucaCompany.belgeTurleri,
            eDefterSubeAdi: lucaCompany.eDefterSubeAdi,
            eDefterSubeKodu: lucaCompany.eDefterSubeKodu,
            webServiceUrl: lucaCompany.webService.url,
            webServiceUsername: webServiceReq.username,
            webServicePassword: webServiceReq.password,
            data: receipts,
            directSend: directSendValue
        };

        try {
            setPending(true);
            const res = await sendReceiptsOnLuca(data);
            setPending(false);
            setSendReceiptsDialog(false);
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: res.data.message, life: 3000 });

            setTimeout(() => {
                getReceiptList();
            }, 3500);
        } catch (error) {
            error.code === 'ERR_NETWORK'
                ? toast.current.show({ severity: 'error', summary: 'Hata', detail: 'İstek zaman aşımına uğradı', life: 3000 })
                : toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
            setPending(false);
        }
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="flex align-items-center justify-content-between">
                <HeaderProgram selectedCompany={selectedCompany} moduleName="E-Defter" />
            </div>
            <h3 className="mb-5">Fiş Tablosu</h3>
            <div className="card flex align-items-end justify-content-between flex-wrap py-4">
                <div className="flex align-items-end gap-5">
                    <div className="w-17rem relative">
                        <label className="mb-2 block">Şirketler</label>
                        <Dropdown
                            dataKey="_id"
                            value={selectedCompanyCode}
                            onChange={(e) => setSelectedCompanyCode(e.value)}
                            options={getCompany(selectedCompany)}
                            optionLabel="label"
                            placeholder="Şirket Seçiniz"
                            filter
                            className={classNames({ 'p-invalid': !selectedCompanyCode, 'w-full': true })}
                            emptyMessage="Şirket Bulunamadı"
                        />
                        {!selectedCompanyCode && (
                            <small className="p-error absolute left-0" style={{ bottom: '-18px' }}>
                                Şirket Seçilmedi
                            </small>
                        )}
                    </div>

                    <div>
                        <label className="mb-2 block">Parçalı Defter</label>
                        <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
                    </div>
                    {checked ? (
                        <>
                            <div className="w-17rem">
                                <label className="block mb-2" htmlFor="cal_date">
                                    Başlangıç Tarihi
                                </label>
                                <Calendar className="w-full" value={startDate} dateFormat="dd/mm/yy" locale="tr" onChange={(e) => setStartDate(e.value)} showIcon placeholder="Lütfen başlangıç tarihi seçiniz" />
                            </div>
                            <div>
                                <label className="block mb-2" htmlFor="cal_date">
                                    Bitiş Tarihi
                                </label>
                                <Calendar value={endDate} dateFormat="dd/mm/yy" locale="tr" onChange={(e) => setEndDate(e.value)} showIcon placeholder="Lütfen bitiş tarihi seçiniz" />
                            </div>
                        </>
                    ) : (
                        <div className="w-17rem">
                            <label className="mb-2 block">E-Defter Dönemi</label>
                            <Calendar className="w-full" value={selectedMonth} view="month" dateFormat="mm/yy" locale="tr" onChange={handleMonthChange} showIcon placeholder="Lütfen bir ay seçiniz" />
                        </div>
                    )}
                    <Button icon="pi pi-ticket" onClick={() => periodChecker()} className="px-4" label="Fiş Getir" disabled={!(selectedCompanyCode && startDate && endDate)} />
                </div>
                <div className="flex align-items-end gap-5">
                    <PortalHeader selectedCompany={selectedCompany} moduleName="E-Defter" />
                </div>
            </div>
            {errorReceiptsCount !== 0 && (
                <Message
                    className="mb-3 h-4rem w-full flex justify-content-start font-semibold"
                    style={{ backgroundColor: '#FC6161' }}
                    sticky
                    severity="error"
                    text="Hatalı fiş kayıtlarını görüntülemek için 'Hatalı Fişleri Listele' butonunu aktif hale getirmelisiniz."
                    closable
                />
            )}
            <div className="card">
                <DataTable
                    ref={dt}
                    value={checkErrorReceipts ? receipts.filter((receipt) => wrongRow(receipt)) : receipts}
                    dataKey="receipt.id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 100, 200]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{totalRecords} fişten {first} ila {last} arası gösteriliyor"
                    globalFilter={globalFilter}
                    header={header}
                    emptyMessage="Fiş Bulunamadı"
                    rowExpansionTemplate={rowExpansionTemplate}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    className="p-datatable-gridlines"
                    selectionMode={'checkbox'}
                    selection={selectedReceipt}
                    onSelectionChange={(e) => setSelectedReceipt(e.value)}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column expander style={{ width: '5rem' }} />
                    <Column field="" header="" style={{ minWidth: '2rem' }} body={(rowData) => rowIsTrue(rowData)}></Column>
                    <Column field="receipt.fis_tarihi" header="Fiş Tarihi" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="receipt.fis_no" header="Fiş No" sortable style={{ minWidth: '8rem' }}></Column>
                    <Column field="receipt.madde_no" header="Yevmiye Madde No" sortable style={{ minWidth: '10rem' }} body={(rowData) => itemNo(rowData.receipt.madde_no)}></Column>
                    <Column field="receipt.tip_id" header="Fiş Türü" body={(rowData) => mapTipNoToLabel(rowData.receipt.tip_id)} sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="receipt.borc" header="Toplam Borç" sortable style={{ minWidth: '12rem' }} body={(rowData) => formatAmount(rowData.receipt.borc, 'TRY')}></Column>
                    <Column field="receipt.alacak" header="Toplam Alacak" sortable style={{ minWidth: '12rem' }} body={(rowData) => formatAmount(rowData.receipt.alacak, 'TRY')}></Column>
                    <Column field="receipt.aciklama" header="Açıklama" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="receipt.kaynak_belge_turu" header="Kaynak Belge Türü" sortable style={{ minWidth: '12rem' }} body={(rowData) => translateDocType(rowData.receipt.kaynak_belge_turu)}></Column>
                    <Column
                        field="receipt.kaynak_belge_turu_aciklama"
                        header="Kaynak Belge Türü Açıklama"
                        sortable
                        style={{ minWidth: '12rem' }}
                        body={(rowData) => docName(rowData.receipt.kaynak_belge_turu_aciklama, rowData.receipt.kaynak_belge_turu)}
                    ></Column>

                    <Column field="receipt.receiptDetails[0].evrak_no" header="Evrak No" style={{ minWidth: '12rem' }} sortable body={(rowData) => docTemplate(rowData, 'evrak_no')}></Column>

                    <Column field="receipt.receiptDetails[0].evrak_tarihi" header="Evrak Tarihi" style={{ minWidth: '12rem' }} sortable body={(rowData) => docTemplate(rowData, 'evrak_tarihi')}></Column>

                    <Column field="receipt.receiptDetails[0].odeme_yontemi" header="Ödeme Yöntemi" style={{ minWidth: '12rem' }} sortable body={(rowData) => rowData?.receipt?.receiptDetails[0]?.odeme_yontemi}></Column>

                    <Column
                        field="receipt.belgesiz_fis"
                        header="Belge Detayı Yoktur"
                        style={{ minWidth: '8rem' }}
                        body={(rowData) => <Checkbox onChange={(e) => check(rowData)} checked={rowData.receipt.belgesiz_fis === 1 ? true : false}></Checkbox>}
                    ></Column>
                </DataTable>
            </div>

            <OneReceiptUpdateDialog oneEditDialog={oneEditDialog} onCloseDialog={() => handleHideOneEditDialog()} onUpdateOneReceipt={handleUpdateOneReceipt} />

            <AllReceiptUpdateDialog allEditDialog={allEditDialog} onCloseDialog={() => setAllEditDialog(false)} onUpdateAllReceipt={handleUpdateAllReceipt} />

            <SendReceiptDialog
                selectedStartDate={startDate}
                receipts={receipts}
                sendReceiptsDialog={sendReceiptsDialog}
                onCloseDialog={() => setSendReceiptsDialog(false)}
                sendReceipts={sendReceipts}
                pending={pending}
                selectedCompanyCode={selectedCompanyCode}
            />
            <Dialog visible={periodVisible} onHide={() => setPeriodVisible(false)} header="Uyarı" modal style={{ width: '450px' }} footer={<Button onClick={() => setPeriodVisible(false)}>Kapat</Button>}>
                <div className="flex align-items-center gap-2">
                    <p className="m-0">
                        Luca döneminiz <span className="text-pink-500 font-bold">{getPeriods(selectedCompany, selectedCompanyCode)}</span> olarak tanımlanmıştır. <br />
                        <span className="text-pink-500 font-bold">{startDate.getFullYear().toString()} </span>
                        yılının fişlerini listeleyebilmek için dönem ayarlaması yapılmalıdır. <br />
                        <a href="https://webconnector.bienteknoloji.com.tr/#/E-Defter/Luca/moduleupdate" target="_blank" rel="noreferrer" className="text-blue-500 underline">
                            Dönem ayarlaması yapmak için tıklayınız.
                        </a>
                    </p>
                </div>
            </Dialog>
        </div>
    );
}
