import React, { useEffect, useState } from 'react';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { addLocale } from 'primereact/api';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';

import { translateDocType, documentData, docName, checkAmountAndCredit, rowIsTrue, wrongRow } from './eDefterDatasoft/utils/controller';
import { getErrorReceiptCount } from './eDefterDatasoft/utils/utils';

import { importReceiptsExcel, sendReceiptsData } from '../module/datasoft/utils/DatasoftRequest';

import SendReceiptDialog from './eDefterDatasoft/components/SendReceiptDialog';
import AllReceiptUpdateDialog from './eDefterDatasoft/components/AllReceiptsUpdateDialog';
import OneReceiptUpdateDialog from './eDefterDatasoft/components/OneReceiptsUpdateDialog';
import Loading from '../Loading';
import { formatAmount, HeaderProgram, formatDate, localeDate, EditButtonTemplate, PortalHeader } from './utils/utils';
import { InputSwitch } from 'primereact/inputswitch';
import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';

export default function ReceiptList() {
    const { selectedCompany } = useSelector((state) => state.userCompany);
    const [receipts, setReceipts] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedCompanyCode, setSelectedCompanyCode] = useState(null);

    const [selectedReceipt, setSelectedReceipt] = useState(null);

    const [oneEditDialog, setOneEditDialog] = useState(false);
    const [allEditDialog, setAllEditDialog] = useState(false);
    const [sendReceiptsDialog, setSendReceiptsDialog] = useState(false);
    const [pending, setPending] = useState(false);
    const [errorReceiptsCount, setErrorReceiptsCount] = useState(0);

    const [receiptFile, setReceiptFile] = useState(null);
    const [accountPlansFile, setAccountPlansFile] = useState(null);
    const [checkErrorReceipts, setCheckErrorReceipts] = useState(false);

    const dt = useRef(null);
    const toast = useRef(null);

    addLocale('tr', localeDate);

    useEffect(() => {
        if (receipts.length !== 0) setErrorReceiptsCount(getErrorReceiptCount(receipts));
    }, [receipts]);

    useEffect(() => {
        if (errorReceiptsCount === 0) {
            setCheckErrorReceipts(false);
        }
    }, [errorReceiptsCount]);

    const getReceiptList = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('receiptsExcel', receiptFile);
        formData.append('accountPlansExcel', accountPlansFile);

        importReceiptsExcel(formData)
            .then((res) => {
                setReceipts(res.data.data);
                setLoading(false);
                setTimeout(() => {
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fişler başarıyla getirildi.', life: 3000 });
                }, 100);
            })
            .catch((error) => {
                setLoading(false);
                setTimeout(() => {
                    if (error) {
                        toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Bir hata oluştu.', life: 3000 });
                    }
                }, 100);
            });

        setReceiptFile(null);
        setAccountPlansFile(null);
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    const rowExpansionTemplate = (rowData) => {
        const receiptDetails = rowData.altBasliklar;
        return (
            <div className="p-3">
                <h5>Fiş Detayı</h5>
                <DataTable emptyMessage="Modül Bulunamadı" value={receiptDetails}>
                    <Column field="hesap_kodu" header="Hesap Kodu" style={{ minWidth: '12rem' }} sortable tooltip="Save" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}></Column>
                    <Column field="hesap_adı" header="Hesap Adı" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="borc" header="Borç" style={{ minWidth: '12rem' }} sortable body={(rowData) => checkAmountAndCredit(rowData.borc, rowData.alacak, 'borc')}></Column>
                    <Column field="alacak" header="Alacak" style={{ minWidth: '12rem' }} sortable body={(rowData) => checkAmountAndCredit(rowData.borc, rowData.alacak, 'alacak')}></Column>
                    <Column field="satir_aciklama" header="Açıklama" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="kaynak_belge_no" header="Evrak No" style={{ minWidth: '12rem' }} sortable body={(rowData) => documentData(rowData.kaynak_belge_no, rowData.kaynak_belge_tarihi, 'kaynak_belge_no')}></Column>
                    <Column field="kaynak_belge_tarihi" header="Evrak Tarihi" style={{ minWidth: '12rem' }} sortable body={(rowData) => documentData(rowData.kaynak_belge_no, rowData.kaynak_belge_tarihi, 'kaynak_belge_tarihi')}></Column>
                    <Column field="odeme_sekli" header="Ödeme Yöntemi" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="kaynak_belge_tipi_aciklama" header="Kaynak Belge Türü" sortable style={{ minWidth: '12rem' }} body={(rowData) => translateDocType(rowData.kaynak_belge_tipi_aciklama)}></Column>
                    <Column field="diger_tur_aciklama" header="Kaynak Belge Türü Açıklama" sortable style={{ minWidth: '12rem' }} body={(rowData) => docName(rowData.diger_tur_aciklama, rowData.kaynak_belge_tipi_aciklama)}></Column>
                </DataTable>
            </div>
        );
    };

    //E defter ortak
    const check = (rowData) => {
        const rec = rowData;
        rec.belgesiz_fis = rec.belgesiz_fis === 1 ? 0 : 1;
        const recCopy = [...receipts];
        const index = recCopy.findIndex((r) => r.fis_no === rec.fis_no);
        recCopy[index] = rec;

        setReceipts(recCopy);
    };

    const handleHideOneEditDialog = () => {
        setOneEditDialog(false);
        setSelectedReceipt(null);
    };

    const handleUpdateOneReceipt = ({ paymentType, docNo, docDate }) => {
        const rec = selectedReceipt[0].altBasliklar;

        rec.forEach((item) => {
            item.kaynak_belge_no = docNo || item.kaynak_belge_no;
            item.kaynak_belge_tarihi = formatDate(docDate, 'slash') || item.kaynak_belge_tarihi;
            item.odeme_sekli = paymentType || item.odeme_sekli;
        });

        const recCopy = [...receipts];

        const index = recCopy.findIndex((r) => r.fis_no === rec.fis_no);

        recCopy[index] = rec;

        setReceipts(recCopy);

        setOneEditDialog(false);
        setSelectedReceipt(null);
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fiş başarıyla güncellendi.', life: 3000 });
    };

    const handleUpdateAllReceipt = ({ paymentType, docIsUpdate, noDocReceipt }) => {
        const recCopy = [...receipts];

        selectedReceipt.forEach((item) => {
            const rec = item;

            if (noDocReceipt) {
                rec.belgesiz_fis = 1;
            } else {
                rec.belgesiz_fis = 0;
            }

            rec.altBasliklar.forEach((i) => {
                if (docIsUpdate) {
                    i.kaynak_belge_no = rec.fis_no || i.kaynak_belge_no;
                    i.kaynak_belge_tarihi = rec.fis_tarihi || i.kaynak_belge_tarihi;
                    i.odeme_sekli = paymentType || i.odeme_sekli;
                }
            });

            const index = recCopy.findIndex((r) => r.fis_no === rec.fis_no);

            recCopy[index] = rec;
        });

        setReceipts(recCopy);

        setAllEditDialog(false);
        setSelectedReceipt(null);
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fişler başarıyla güncellendi.', life: 3000 });
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div className="flex align-items-center gap-5">
                <h4 className="m-0">Fiş Listesi</h4>
                {receipts.length !== 0 && (
                    <div className="flex align-items-center gap-5">
                        <div className="m-0 p-badge p-overlay-badge p-badge-info">
                            Hatalı Fiş Sayısı <span className="p-badge p-badge-danger">{errorReceiptsCount}</span>
                        </div>
                        <div className="flex align-items-end gap-2">
                            <label className="block font-normal">Hatalı Fişleri Listele</label>
                            <InputSwitch
                                checked={checkErrorReceipts}
                                onChange={(e) => {
                                    setCheckErrorReceipts(e.value);
                                    setSelectedReceipt(null);
                                }}
                            />
                        </div>
                        <div className="p-badge p-overlay-badge p-badge-success text-white">
                            Fiş Adedi <span className="p-badge p-badge-danger">{receipts.length}</span>
                        </div>
                    </div>
                )}
            </div>
            <div>
                {receipts.length !== 0 && (
                    <h6 className="mb-0 mr-6 p-badge p-overlay-badge p-badge-danger text-white h-auto w-auto">
                        Toplam Borç{' '}
                        {formatAmount(
                            receipts.reduce((acc, item) => acc + parseFloat(item.borc), 0),
                            'TRY'
                        )}
                    </h6>
                )}
                {receipts.length !== 0 && (
                    <h6 className="m-0 p-badge p-overlay-badge p-badge-success text-white h-auto w-auto">
                        Toplam Alacak{' '}
                        {formatAmount(
                            receipts.reduce((acc, item) => acc + parseFloat(item.alacak), 0),
                            'TRY'
                        )}
                    </h6>
                )}
            </div>
            <div>
                <EditButtonTemplate data={selectedReceipt} onEdit={() => setOneEditDialog(true)} onAllEdit={() => setAllEditDialog(true)} />
                <Button icon="pi pi-arrow-right" severity="success" className="mr-4 text-white" label="E-Defter Oluştur" onClick={() => setSendReceiptsDialog(true)} disabled={!(receipts.length > 0 && errorReceiptsCount === 0)} loading={pending} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const sendReceipts = async (enteredBy, vknTckn, startDate, endDate, directSendValue) => {
        const module = selectedCompany.modules.filter((module) => module.title === 'E-Defter')[0];

        const programReq = module.setting.settings.program.requirements[0];
        const webServiceReq = module.setting.settings.webService.requirements;

        setSendReceiptsDialog(false);

        const data = {
            taxNumber: vknTckn,
            enteredBy: enteredBy,
            eDefterSubeAdi: programReq.eDefterSubeAdi,
            eDefterSubeKodu: programReq.eDefterSubeKodu,
            webServiceUrl: module.setting.settings.webService.url,
            username: webServiceReq.username,
            password: webServiceReq.password,
            data: receipts,
            startDate: formatDate(startDate, 'dashReverse'),
            endDate: formatDate(endDate, 'dashReverse'),
            directSend: directSendValue
        };

        try {
            setPending(true);
            const res = await sendReceiptsData(data);
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: res.data.message, life: 3000 });
        } catch (error) {
            error.code === 'ERR_NETWORK'
                ? toast.current.show({ severity: 'error', summary: 'Hata', detail: 'İstek zaman aşımına uğradı', life: 3000 })
                : toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }
        setPending(false);
        setSelectedReceipt(null);
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="flex align-items-center justify-content-between">
                <HeaderProgram selectedCompany={selectedCompany} moduleName="E-Defter" />
            </div>
            <br />
            <h3 className="mb-5">Fiş Tablosu</h3>
            <div className="card flex align-items-end justify-content-between">
                <div className="flex justify-content-between w-full">
                    <div className="flex align-items-end gap-5">
                        <div>
                            <label className="mb-2 block">Fiş Dosyası</label>
                            <FileUpload chooseLabel="Fiş Excel dosyasını seçiniz." mode="basic" accept=".xlsx" maxFileSize={100000000} onSelect={(e) => setReceiptFile(e.files[0])} />
                            <small className="block text-pink-500 absolute">*Dosya uzantısı .xlsx olmalıdır</small>
                        </div>
                        <div>
                            <label className="mb-2 block">Hesap Planları Dosyası</label>
                            <FileUpload chooseLabel="Hesap Planları dosyasını seçiniz." mode="basic" accept=".xlsx" maxFileSize={100000000} onSelect={(e) => setAccountPlansFile(e.files[0])} />
                            <small className="block text-pink-500 absolute">*Dosya uzantısı .xlsx olmalıdır</small>
                        </div>
                        <Button icon="pi pi-ticket" onClick={() => getReceiptList()} className="px-4" label="Dosyaları Yükle" disabled={!(receiptFile && accountPlansFile)} />
                    </div>
                    <div className="flex align-items-end gap-5">
                        <PortalHeader selectedCompany={selectedCompany} moduleName="E-Defter" />
                    </div>
                </div>
            </div>
            {errorReceiptsCount !== 0 && (
                <Message
                    className="mb-3 h-4rem w-full flex justify-content-start font-semibold"
                    style={{ backgroundColor: '#FC6161' }}
                    sticky
                    severity="error"
                    text="Hatalı fiş kayıtlarını görüntülemek için 'Hatalı Fişleri Listele' butonunu aktif hale getirmelisiniz."
                    closable
                />
            )}
            <div className="card">
                <DataTable
                    ref={dt}
                    value={checkErrorReceipts ? receipts.filter((receipt) => wrongRow(receipt)) : receipts}
                    dataKey="fis_no"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 100, 200]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{totalRecords} fişten {first} ila {last} arası gösteriliyor"
                    globalFilter={globalFilter}
                    header={header}
                    emptyMessage="Fiş Bulunamadı"
                    rowExpansionTemplate={rowExpansionTemplate}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    className="p-datatable-gridlines"
                    selectionMode={'checkbox'}
                    selection={selectedReceipt}
                    onSelectionChange={(e) => setSelectedReceipt(e.value)}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column expander style={{ width: '5rem' }} />
                    <Column field="" header="" style={{ minWidth: '2rem' }} body={(rowData) => rowIsTrue(rowData)}></Column>
                    <Column field="fis_tarihi" header="Fiş Tarihi" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="fis_no" header="Fiş No" sortable style={{ minWidth: '8rem' }}></Column>
                    <Column field="borc" header="Toplam Borç" sortable style={{ minWidth: '12rem' }} body={(rowData) => formatAmount(rowData?.borc, 'TRY')}></Column>
                    <Column field="alacak" header="Toplam Alacak" sortable style={{ minWidth: '12rem' }} body={(rowData) => formatAmount(rowData?.alacak, 'TRY')}></Column>
                    <Column field="baslik_aciklama" header="Açıklama" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="belgesiz_fis" header="Belge Detayı Yoktur" style={{ minWidth: '8rem' }} body={(rowData) => <Checkbox onChange={(e) => check(rowData)} checked={rowData?.belgesiz_fis === 1 ? true : false}></Checkbox>}></Column>
                </DataTable>
            </div>

            <OneReceiptUpdateDialog oneEditDialog={oneEditDialog} onCloseDialog={() => handleHideOneEditDialog()} onUpdateOneReceipt={handleUpdateOneReceipt} />

            <AllReceiptUpdateDialog allEditDialog={allEditDialog} onCloseDialog={() => setAllEditDialog(false)} onUpdateAllReceipt={handleUpdateAllReceipt} />

            <SendReceiptDialog receipts={receipts} sendReceiptsDialog={sendReceiptsDialog} onCloseDialog={() => setSendReceiptsDialog(false)} sendReceipts={sendReceipts} pending={pending} selectedCompanyCode={selectedCompanyCode} />
        </div>
    );
}
