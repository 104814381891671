import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { convertReq, convertName, convertNameLuca } from '../../utils/utils';
import { updateProgramReq, setProgramReq } from '../../../../store/modules/lucaSlice';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';

export default function ProgramReq({ req = null, title = null, onLogin = () => {}, onUpdatePassword = () => {}, isLogin = true }) {
    const { updatedModule } = useSelector((state) => state.modules);
    const { selectedCompany: userSelectedCompany } = useSelector((state) => state.userCompany);
    const { selectedProgram } = useSelector((state) => state.modules);
    const { programReq } = useSelector((state) => state.luca);
    const [showPassword, setShowPassword] = useState(true);
    const [updatePassword, setUpdatePassword] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedProgram && !req) {
            dispatch(setProgramReq(convertReq(selectedProgram?.requirements.split(','))));
        }

        if (req) {
            dispatch(setProgramReq(req));
        }
    }, [selectedProgram, req, dispatch]);

    const onProgramInputChange = async (e, title) => {
        await dispatch(updateProgramReq({ title, value: e.target.value }));
    };

    const handleUpdateLucaPassword = () => {
        onUpdatePassword({
            moduleId: updatedModule?.id,
            companyId: userSelectedCompany?.id,
            password: programReq?.password,
            settingId: updatedModule?.setting.id
        });
        setUpdatePassword(null);
    };

    return (
        <div className="w-full">
            <form className="mt-5" onSubmit={onLogin}>
                <h3>{title || selectedProgram?.title}</h3>
                <div className="flex">
                    {programReq &&
                        Object.keys(programReq)?.map((req, i) => {
                            return (
                                <div key={i} className="py-3 mr-4 w-full">
                                    <div className="w-full mb-2">
                                        <label htmlFor={req}>{convertNameLuca(req)}</label>
                                    </div>
                                    <div className="w-full relative">
                                        <InputText onChange={(e) => onProgramInputChange(e, req)} placeholder={convertName(req)} value={programReq[req]} type={req === 'password' && showPassword ? 'password' : 'text'} className="w-full" />
                                        {req === 'password' && (
                                            <Button
                                                type="button"
                                                className="p-button-text p-button-sm p-0 text-white ml-2 absolute right-0 top-0 h-full"
                                                icon={showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                                onClick={() => setShowPassword(!showPassword)}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                </div>
                {/* <div className="py-3 w-full">
                    <div className="w-full mb-2">
                        <label>Şifre Güncelle</label>
                    </div>
                    <div className="flex">
                        <InputText
                            onChange={(e) => setUpdatePassword(e.target.value)}
                            placeholder={"Şifre Güncelle"} value={updatePassword}
                            type={"text"}
                            className='mr-3 w-3'
                        />
                        <Button
                            type="button"
                            className="text-white"
                            icon={"pi pi-sync"}
                            label='Güncelle'
                            onClick={() => handleUpdateLucaPassword()}
                        />
                    </div>
                </div> */}
                {!isLogin ? (
                    <Message className="py-3 my-3" severity="warn" text="Şirketleri ve periyotları görüntülemek için giriş yapınız." />
                ) : (
                    <Message className="py-3 my-3" severity="success" text="Şirketleri ve periyotları oluşturabilirsiniz." />
                )}
                <div className="w-full">
                    <Button label="Giriş Yap" icon="pi pi-check" className="p-button-success text-white p-mr-2 mt-3" />
                    <Button type="button" className="text-white ml-4" icon={'pi pi-sync'} label="Şifre Güncelle" onClick={() => handleUpdateLucaPassword()} />
                </div>
            </form>
        </div>
    );
}
