import React, { useEffect, useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { loginParasut, getSalesInvoices, getVatExemptionReasons, viewInvoice, sendInvoice } from '../module/parasut/utils/parasutRequest';
import { getErrorReceiptCount, getInvoice, invoiceType, getInvoiceStatus, sendTypeEnum, getSendReqData, sendDisabledStatus } from './eFaturaParasut/utils/utils';
import { controlAllData, rowIsTrue, wrongRow } from './eFaturaParasut/utils/controller';
import { addLocale } from 'primereact/api';
import { localeDate, formatDate, HeaderProgram, formatAmount, PortalHeader } from './utils/utils';
import { Dialog } from 'primereact/dialog';
import ReasonCode from '../../assets/data/ReasonCode.json';
import SendDialog from './eFaturaParasut/components/SendDialog';
import LogDialog from './eFaturaParasut/components/LogDialog';
import ViewInvoiceDialog from './eFaturaParasut/components/ViewInvoiceDialog';
import VatDialog from './eFaturaParasut/components/VatDialog';
import VatWithholdingDialog from './eFaturaParasut/components/VatWithholdingDialog';
import { getCounters } from '../../service/request/countersRequest';
import { classNames } from 'primereact/utils';
import { InputSwitch } from 'primereact/inputswitch';
import Loading from '../Loading';

export default function ReceiptList() {
    const [invoices, setInvoices] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const { selectedCompany } = useSelector((state) => state.userCompany);
    const [date, setDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [loading, setLoading] = useState(false);
    const [selectedCompanyCode, setSelectedCompanyCode] = useState(null);
    const [selectedAlias, setSelectedAlias] = useState(null);
    const [checked, setChecked] = useState('');
    const [vatExcemptionDialog, setVatExcemptionDialog] = useState(false);
    const [vatWithholdingDialog, setVatWithholdingDialog] = useState(false);
    const [vatExcemption, setVatExcemption] = useState([]);
    const [selectedVatExemption, setSelectedVatExemption] = useState(null);
    const [selectedInvoiceUnit, setSelectedInvoiceUnit] = useState(null);
    const [vatDialog, setVatDialog] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [selectedInvoiceDialog, setSelectedInvoiceDialog] = useState(false);
    const [errorReceiptsCount, setErrorReceiptsCount] = useState(0);
    const [invoiceViewDialog, setInvoiceViewDialog] = useState(false);
    const [invoiceView, setInvoiceView] = useState(null);
    const [reasonCode, setReasonCode] = useState(null);
    const [selectedInvoiceType, setSelectedInvoiceType] = useState(selectedInvoice?.attributes.item_type);
    const [logDialog, setLogDialog] = useState(false);
    const [sendDialog, setSendDialog] = useState(false);
    const [counters, setCounters] = useState([]);
    const [selectedCounter, setSelectedCounter] = useState(null);

    const [selectedReceipt, setSelectedReceipt] = useState(null);

    const [pending, setPending] = useState(false);
    const [checkErrorReceipts, setCheckErrorReceipts] = useState(false);

    addLocale('tr', localeDate);

    useEffect(() => {
        if (vatExcemptionDialog && vatExcemption.length === 0) {
            const req = async () => {
                const res = await getVatExemptionReasons();
                setVatExcemption(res.data.data);
            };
            req();
        }
    }, [vatExcemptionDialog, vatExcemption]);

    useEffect(() => {
        if (invoices.length !== 0) setErrorReceiptsCount(getErrorReceiptCount(invoices));
    }, [invoices]);

    useEffect(() => {
        if (selectedInvoiceUnit) {
            invoices.forEach((invoice) => {
                invoice.sales_invoice_details.forEach((detail) => {
                    if (detail.id === selectedInvoiceUnit.id) {
                        setReasonCode(ReasonCode[invoice.attributes.item_type]);
                    }
                });
            });
        }
    }, [selectedInvoiceUnit, invoices]);

    const getReceiptList = async () => {
        setSelectedReceipt(null);
        setLoading(true);
        let selectedModule = selectedCompany?.modules?.find((module) => module?.title === 'E-Fatura');
        const req = selectedModule?.setting?.settings?.program?.requirements;

        const res = await loginParasut(req[0].username, req[0].password, req[0].clientId, req[0].clientSecret);
        const company = selectedModule?.setting?.settings?.program?.parasutCompanies?.filter((company) => company.id === selectedCompanyCode)[0];

        let invoiceList = null;
        try {
            invoiceList = await getSalesInvoices(res.data.data.access_token, company.id, formatDate(date, 'dashReverse'));
            getCounters(selectedCompanyCode).then((res) => {
                setCounters(res.data.data);
            });
        } catch (error) {
            setLoading(false);
            setTimeout(() => {
                error.code === 'ERR_NETWORK'
                    ? toast.current.show({ severity: 'error', summary: 'Hata', detail: 'İstek zaman aşımına uğradı', life: 3000 })
                    : toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
            }, 100);
            return;
        }

        const copy = [...invoiceList.data.data];
        copy.forEach((invoice) => {
            if (invoice.attributes.item_type === 'export') {
                invoice.attributes.send_type = 'ISTISNA';
            }

            if (invoice.attributes.item_type === 'refund' && invoice.contact.attributes.e_invoice_user === true) {
                invoice.attributes.send_type = 'TEMELFATURA';
            }

            invoice.sales_invoice_details.forEach((detail) => {
                if (detail.attributes.vat_withholding_rate !== '0.0' && detail.attributes.vat_withholding_rate !== null) {
                    invoice.attributes.item_type = 'TEVKIFAT';
                }
            });
        });
        setInvoices(copy);
        setLoading(false);

        setTimeout(() => {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fatura başarıyla getirildi.', life: 3000 });
        }, 100);
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    const rowExpansionTemplate = (rowData) => {
        const salesInvoiceDetails = rowData.sales_invoice_details;
        return (
            <div className="p-3">
                <h5>Fatura Detayı</h5>
                <DataTable emptyMessage="Ürün Bulunamadı" value={salesInvoiceDetails} dataKey="id">
                    <Column field="product.attributes.name" header="Ürün Adı" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="product.attributes.code" header="Ürün Kodu" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="product.attributes.unit" header="Birim" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="attributes.vat_rate" header="KDV Oranı" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column header="İşlemler" body={actionSubTableTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>
        );
    };

    const sendViewReceipts = async (rowData) => {
        const reqData = getSendReqData(selectedCompany, selectedCompanyCode, [rowData]);

        setPending(true);

        try {
            const res = await viewInvoice(reqData);

            if (res.data.success === false) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: res.data.message, life: 3000 });
            } else {
                setInvoiceView(res.data.data);
                setInvoiceViewDialog(true);
            }

            setPending(false);
        } catch (error) {
            setPending(false);
            toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }
    };

    const sendReceipts = async () => {
        const reqData = getSendReqData(selectedCompany, selectedCompanyCode, [...selectedReceipt]);
        setSendDialog(false);
        setPending(true);
        try {
            const res = await sendInvoice(reqData);

            if (res.data.success === false) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: res.data.message, life: 3000 });
            } else {
                setPending(false);
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Faturalar başarıyla gönderildi.', life: 3000 });
                setTimeout(() => {
                    getReceiptList();
                }, 1500);
            }
        } catch (error) {
            setPending(false);
            toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message || 'Bir hata oluştu', life: 3000 });
        }
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div className="flex align-items-center gap-5">
                <h4 className="m-0">Fatura Listesi</h4>
                {invoices.length !== 0 && (
                    <div className="flex align-items-center">
                        <div className="m-0 p-badge p-overlay-badge p-badge-info">
                            Hatalı Fatura Sayısı <span className="p-badge p-badge-danger">{errorReceiptsCount}</span>
                        </div>
                        <div className="ml-6 flex align-items-end gap-2">
                            <label className="block text-white font-normal">Hatalı Faturalar</label>
                            <InputSwitch
                                checked={checkErrorReceipts}
                                onChange={(e) => {
                                    setCheckErrorReceipts(e.value);
                                    setSelectedReceipt(null);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div>
                <Button
                    icon="pi pi-arrow-right"
                    severity="success"
                    className="mr-4 text-white"
                    label="Faturaları Gönder"
                    visible={invoices.length > 0}
                    loading={pending}
                    disabled={sendDisabledStatus(selectedReceipt)}
                    onClick={() => setSendDialog(true)}
                />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const getAliasTemplate = (rowData) => {
        if (rowData?.contact.attributes.e_invoice_user === true) {
            const copy = [];
            rowData.contact.attributes.aliasses.forEach((alias) => {
                copy.push({ label: alias.alias, value: alias.alias });
            });
            if (selectedAlias === null) {
                setSelectedAlias(rowData.contact.attributes.alias.alias);
            }
            return (
                <>
                    <div className="mb-2">
                        <label>Mail Güncelleme</label>
                    </div>
                    <Dropdown value={selectedAlias} onChange={(e) => setSelectedAlias(e.value)} options={copy} optionLabel="label" placeholder="Mail Seçiniz" filter className="w-full mb-4" emptyMessage="Mail Bulunamadı" />
                </>
            );
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="align-items-center flex">
                <Button
                    icon="pi pi-eye"
                    severity="info"
                    className="mr-2"
                    size="large"
                    tooltip="Görüntüle"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    disabled={!controlAllData(rowData)}
                    rounded
                    loading={pending}
                    onClick={() => sendViewReceipts(rowData)}
                />
                <Button
                    icon="pi pi-pencil"
                    severity="success"
                    tooltip="Düzenle"
                    size="small"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded
                    className="text-white"
                    disabled={(rowData.attributes.item_type === 'refund' || rowData.attributes.item_type === 'export') && rowData.contact.attributes.e_invoice_user === false}
                    onClick={() => {
                        setSelectedInvoice(rowData);
                        setSelectedCounter(rowData.counterId);
                        setSelectedInvoiceDialog(true);
                    }}
                />
            </div>
        );
    };

    const setVatWithholdingButton = (selectedVatWithholding) => {
        const copy = [...invoices];
        copy.forEach((invoice) => {
            invoice.sales_invoice_details.forEach((detail) => {
                if (detail.id === selectedInvoiceUnit.id) {
                    detail.attributes.vat_withholding_reason = selectedVatWithholding;
                }
            });
        });
        setInvoices(copy);
        setVatWithholdingDialog(false);
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Tevkifat başarıyla eklendi.', life: 3000 });
    };

    const setVatExemptionButton = () => {
        const copy = [...invoices];
        copy.forEach((invoice) => {
            invoice.sales_invoice_details.forEach((detail) => {
                if (detail.id === selectedInvoiceUnit.id) {
                    detail.attributes.vat_exemption_reason = vatExcemption.find((item) => item.value === selectedVatExemption);
                }
            });
        });
        setSelectedVatExemption(null);
        setInvoices(copy);
        setVatExcemptionDialog(false);
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'İstisna başarıyla eklendi.', life: 3000 });
    };

    const setUpdateInvoiceButton = () => {
        const copy = [...invoices];
        setSelectedInvoiceDialog(false);
        copy.forEach((invoice) => {
            if (invoice.id === selectedInvoice.id) {
                invoice.counterId = selectedCounter || invoice.counterId;
                invoice.attributes.send_type = checked;
                invoice.attributes.item_type = selectedInvoiceType || invoice.attributes.item_type;
                invoice.contact.attributes.alias.alias = selectedAlias;
            }

            if (invoice.attributes.item_type === 'export') {
                invoice.attributes.send_type = 'ISTISNA';
            }

            if (invoice.attributes.item_type === 'refund' && invoice.contact.attributes.e_invoice_user === true) {
                invoice.attributes.send_type = 'TEMELFATURA';
            }
        });

        setChecked('');
        setSelectedAlias(null);
        setSelectedInvoiceType(null);
        setInvoices(copy);
        setSelectedCounter(null);
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Fatura başarıyla güncellendi.', life: 3000 });
    };

    const actionSubTableTemplate = (rowData) => {
        if (rowData.attributes.vat_withholding_rate !== '0.0' && rowData.attributes.vat_rate !== '0.0') {
            return (
                <React.Fragment>
                    <Button
                        icon="pi pi-book"
                        severity="success"
                        tooltip="Tevkifat Ekle"
                        className="text-white"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        onClick={(e) => {
                            setVatWithholdingDialog(true);
                            setSelectedInvoiceUnit(rowData);
                        }}
                    />
                    {((rowData.attributes.excise_duty_value !== null && rowData.attributes.excise_duty_value !== '0.0' && rowData.attributes.excise_duty_rate !== null && rowData.attributes.excise_duty_rate !== '0.0') ||
                        (rowData.attributes.communications_tax_rate !== '0.0' && rowData.attributes.communications_tax_rate !== null)) && (
                        <Button
                            icon="pi pi-credit-card"
                            className="ml-2"
                            severity="info"
                            tooltip="Vergi Ekle"
                            tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                            rounded
                            onClick={(e) => {
                                setVatDialog(true);
                                setSelectedInvoiceUnit(rowData);
                            }}
                        />
                    )}
                </React.Fragment>
            );
        } else if (rowData.attributes.vat_rate === '0.0') {
            return (
                <React.Fragment>
                    <Button
                        icon="pi pi-file-excel"
                        tooltip="İstisna Ekle"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        onClick={(e) => {
                            setVatExcemptionDialog(true);
                            setSelectedInvoiceUnit(rowData);
                        }}
                    />
                    {((rowData.attributes.excise_duty_value !== null && rowData.attributes.excise_duty_value !== '0.0' && rowData.attributes.excise_duty_rate !== null && rowData.attributes.excise_duty_rate !== '0.0') ||
                        (rowData.attributes.communications_tax_rate !== '0.0' && rowData.attributes.communications_tax_rate !== null)) && (
                        <Button
                            icon="pi pi-credit-card"
                            className="ml-2"
                            severity="info"
                            tooltip="Vergi Ekle"
                            tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                            rounded
                            onClick={(e) => {
                                setVatDialog(true);
                                setSelectedInvoiceUnit(rowData);
                            }}
                        />
                    )}
                </React.Fragment>
            );
        } else if (
            (rowData.attributes.excise_duty_value !== null && rowData.attributes.excise_duty_value !== '0.0' && rowData.attributes.excise_duty_rate !== null && rowData.attributes.excise_duty_rate !== '0.0') ||
            (rowData.attributes.communications_tax_rate !== '0.0' && rowData.attributes.communications_tax_rate !== null)
        ) {
            <React.Fragment>
                <Button
                    icon="pi pi-credit-card"
                    className="ml-2"
                    severity="info"
                    tooltip="Vergi Ekle"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded
                    onClick={(e) => {
                        setVatDialog(true);
                        setSelectedInvoiceUnit(rowData);
                    }}
                />
            </React.Fragment>;
        }
    };

    const onCloseVatExemptionDialog = () => {
        setVatExcemptionDialog(false);
        setSelectedVatExemption(null);
        setSelectedInvoiceUnit(null);
    };

    const onCloseVatDialog = () => {
        setVatDialog(false);
        setSelectedInvoiceUnit(null);
    };

    const onCloseInvoiceDialog = () => {
        setSelectedInvoiceDialog(false);
        setChecked('');
        setSelectedAlias(null);
        setSelectedInvoiceType(selectedInvoice?.attributes.item_type);
        setSelectedCounter(null);
    };

    const paperOrElectronic = (rowData) => {
        if (rowData?.contact.attributes.e_invoice_user === true) {
            if (checked === '') {
                setChecked(rowData.attributes.send_type);
            }
            return (
                <div>
                    <div className="mb-2">
                        <label>Gönderim Şekli</label>
                    </div>
                    <div className="flex align-items-center mb-3">
                        <RadioButton inputId="checked1" value="TEMELFATURA" onChange={(e) => setChecked(e.value)} checked={checked === 'TEMELFATURA'} />
                        <label htmlFor="checked1" className="ml-2">
                            Temel Fatura
                        </label>
                    </div>
                    <div className="flex align-items-center">
                        <RadioButton inputId="checked2" value="TICARIFATURA" onChange={(e) => setChecked(e.value)} checked={checked === 'TICARIFATURA'} />
                        <label htmlFor="checked2" className="ml-2">
                            Ticari Fatura
                        </label>
                    </div>
                </div>
            );
        } else {
            if (checked === '') {
                setChecked(rowData.attributes.send_type);
            }
            return (
                <div>
                    <div className="mb-2">
                        <label>Gönderim Şekli</label>
                    </div>
                    <div className="flex align-items-center mb-3">
                        <RadioButton inputId="checked1" value="KAGIT" onChange={(e) => setChecked(e.value)} checked={checked === 'KAGIT'} />
                        <label htmlFor="checked1" className="ml-2">
                            Kağıt
                        </label>
                    </div>
                    <div className="flex align-items-center">
                        <RadioButton inputId="checked2" value="ELEKTRONIK" onChange={(e) => setChecked(e.value)} checked={checked === 'ELEKTRONIK'} />
                        <label htmlFor="checked2" className="ml-2">
                            Elektronik
                        </label>
                    </div>
                </div>
            );
        }
    };

    const setTaxButton = (selectedExciseDuty, selectedCommunicationsTax) => {
        const copy = [...invoices];
        copy.forEach((invoice) => {
            invoice.sales_invoice_details.forEach((detail) => {
                if (detail.id === selectedInvoiceUnit.id) {
                    detail.attributes.excise_duty_reason = selectedExciseDuty || detail.attributes.excise_duty_reason;
                    detail.attributes.communications_tax_reason = selectedCommunicationsTax || detail.attributes.communications_tax_reason;
                }
            });
        });
        setInvoices(copy);
        setVatDialog(false);
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Vergi başarıyla eklendi.', life: 3000 });
    };

    const onCloseInvoiceViewDialog = () => {
        setInvoiceViewDialog(false);
        setInvoiceView(null);
    };

    const invoicesType = () => {
        const invoiceArray = [];
        let shouldPush = true;
        selectedInvoice.sales_invoice_details.forEach((detail) => {
            if (detail.attributes.vat_withholding_rate !== '0.0' && detail.attributes.vat_withholding_rate !== null) {
                if (shouldPush && selectedInvoice.attributes.item_type === 'TEVKIFAT') {
                    invoiceArray.push({ label: 'İade Faturası', value: 'refund' }, { label: 'Tevkifat Faturası', value: 'TEVKIFAT' });
                    shouldPush = false;
                } else if (shouldPush && selectedInvoice.attributes.item_type === 'refund') {
                    invoiceArray.splice(0, invoiceArray.length);
                    shouldPush = false;
                }
            }
        });

        if (shouldPush) {
            invoiceArray.push(
                { label: 'Satış Faturası', value: 'invoice' },
                { label: 'İhracat Faturası', value: 'export' },
                { label: 'İstisna Faturası', value: 'ISTISNA' },
                { label: 'Özel Matrah Faturası', value: 'OZELMATRAH' },
                { label: 'İhraç Kayıtlı Fatura', value: 'IHRACKAYITLI' }
            );
            shouldPush = false;
        }

        return invoiceArray;
    };

    const getCounterTemplate = (rowData) => {
        if (rowData.counterId === null) {
            return <span className="p-badge p-badge-danger text-white">Boş</span>;
        }
        return <span className="p-badge p-badge-success text-white">{rowData.counterId.prefix}</span>;
    };

    const getCounterFilter = () => {
        let invoiceType = selectedInvoice?.contact?.attributes?.e_invoice_user;
        if (invoiceType === true) {
            return counters
                ?.filter((item) => item.invoice_type.name === 'E-Fatura')
                .map((item) => {
                    return { id: item.id, prefix: item.prefix };
                });
        } else if (invoiceType === false) {
            return counters
                ?.filter((item) => item.invoice_type.name === 'E-Arşiv')
                .map((item) => {
                    return { id: item.id, prefix: item.prefix };
                });
        } else {
            return counters;
        }
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="flex align-items-center justify-content-between">
                <HeaderProgram selectedCompany={selectedCompany} moduleName={'E-Fatura'} />
            </div>
            <br />
            <h3 className="mb-5">Fatura Tablosu</h3>
            <div className="card flex align-items-end justify-content-between flex-wrap py-4">
                <div className="flex align-items-end gap-5">
                    <div className="w-17rem relative">
                        <label className="mb-2 block">Şirketler</label>
                        <Dropdown
                            dataKey="_id"
                            value={selectedCompanyCode}
                            onChange={(e) => setSelectedCompanyCode(e.value)}
                            options={getInvoice(selectedCompany)}
                            optionLabel="label"
                            placeholder="Şirket Seçiniz"
                            filter
                            className={classNames({ 'p-invalid': !selectedCompanyCode, 'w-full': true })}
                            emptyMessage="Şirket Bulunamadı"
                        />
                        {!selectedCompanyCode && (
                            <small className="p-error absolute left-0" style={{ bottom: '-18px' }}>
                                Şirket Seçilmedi
                            </small>
                        )}
                    </div>
                    <div className="w-17rem">
                        <label className="block mb-2" htmlFor="cal_date">
                            Tarih
                        </label>
                        <Calendar className="w-full" value={date} dateFormat="dd/mm/yy" locale="tr" onChange={(e) => setDate(e.value)} showIcon placeholder="Lütfen başlangıç tarihi seçiniz" />
                    </div>
                    <Button icon="pi pi-ticket" onClick={() => getReceiptList()} className="px-4" label="Fatura Getir" disabled={!(selectedCompanyCode && date)} />
                </div>
                <div className="flex align-items-end gap-5">
                    <Button icon="pi pi-ticket" className="px-4 " label="Geçmiş Faturalar" onClick={() => setLogDialog(true)} disabled={!(selectedCompanyCode && date)} />
                    <PortalHeader selectedCompany={selectedCompany} moduleName={'E-Fatura'} />
                </div>
            </div>

            <div className="card">
                <DataTable
                    ref={dt}
                    value={checkErrorReceipts ? invoices.filter((receipt) => wrongRow(receipt)) : invoices}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 100, 200]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{totalRecords} faturadan {first} ila {last} arası gösteriliyor"
                    globalFilter={globalFilter}
                    header={header}
                    emptyMessage="Fatura Bulunamadı"
                    rowExpansionTemplate={rowExpansionTemplate}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    className="p-datatable-gridlines"
                    selectionMode={'checkbox'}
                    selection={selectedReceipt}
                    onSelectionChange={(e) => setSelectedReceipt(e.value)}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column expander style={{ width: '2rem' }} />
                    <Column style={{ minWidth: '2rem' }} body={(rowData) => rowIsTrue(rowData)}></Column>
                    <Column field="attributes.send_type" sortable header="Gönderim Şekli" style={{ minWidth: '10rem' }} body={(rowData) => sendTypeEnum[rowData.attributes.send_type]}></Column>
                    <Column field="contact.attributes.e_invoice_user" sortable header="Belge Tipi" style={{ minWidth: '6rem' }} body={(rowData) => getInvoiceStatus(rowData.contact.attributes.e_invoice_user)}></Column>
                    <Column field="attributes.issue_date" header="Fatura Tarihi" sortable style={{ minWidth: '6rem' }} body={(rowData) => formatDate(rowData.attributes.issue_date, 'dot')}></Column>
                    <Column field="attributes.item_type" header="Fatura Türü" sortable style={{ minWidth: '6rem' }} body={(rowData) => invoiceType[rowData.attributes.item_type]}></Column>
                    <Column field="contact.attributes.name" header="İsim" sortable style={{ minWidth: '6rem' }}></Column>
                    <Column field="attributes.tax_number" header="Vergi No" sortable style={{ minWidth: '6rem' }}></Column>
                    <Column field="attributes.invoice_no" header="Evrak No" sortable style={{ minWidth: '6rem' }}></Column>
                    <Column field="attributes.before_taxes_total" header="Vergiler Hariç Tutar" sortable body={(rowData) => formatAmount(rowData.attributes.before_taxes_total, rowData.attributes.currency)} style={{ minWidth: '6rem' }}></Column>
                    <Column field="attributes.total_vat" header="Vergi Tutarı" sortable body={(rowData) => formatAmount(rowData.attributes.total_vat, rowData.attributes.currency)} style={{ minWidth: '6rem' }}></Column>
                    <Column field="attributes.total_paid" header="Vergiler Dahil Tutar" sortable body={(rowData) => formatAmount(rowData.attributes.total_paid, rowData.attributes.currency)} style={{ minWidth: '6rem' }}></Column>
                    <Column field="contact.attributes.alias.alias" sortable header="Alıcı Posta Kutusu" style={{ minWidth: '6rem' }}></Column>
                    <Column field="counterId.prefix" header="Sayaç Ön Ek" sortable style={{ minWidth: '6rem' }} body={(rowData) => getCounterTemplate(rowData)}></Column>
                    <Column header="İşlemler" body={actionBodyTemplate} className="text-center" exportable={false} style={{ minWidth: '5rem' }}></Column>
                </DataTable>
            </div>

            <Dialog header="Muafiyet Ekleme" visible={vatExcemptionDialog} style={{ width: '30vw' }} modal onHide={() => onCloseVatExemptionDialog()}>
                <div className="py-3">
                    <div className="mb-2">
                        <label>Muafiyet Sebebi</label>
                    </div>

                    <div className="">
                        <Dropdown
                            dataKey="value"
                            value={selectedVatExemption}
                            onChange={(e) => setSelectedVatExemption(e.value)}
                            options={reasonCode?.map((option) => ({ value: option.value, text: option.text.substring(0, 75) }))}
                            optionLabel="text"
                            placeholder="İstisna Muafiyet Kodu Seçiniz"
                            filter
                            className="w-full"
                            emptyMessage="İstisna Muafiyet Kodu Bulunamadı"
                            emptyFilterMessage="İstisna Muafiyet Kodu Bulunamadı"
                        />
                    </div>
                    <div className="justify-content-end flex mt-5">
                        <Button icon="pi pi-check" label="Muafiyet Ekle" onClick={() => setVatExemptionButton()} disabled={!selectedVatExemption} />
                    </div>
                </div>
            </Dialog>

            {selectedInvoiceDialog && (
                <Dialog header="Güncelleme" visible={selectedInvoiceDialog} style={{ width: '30vw' }} modal onHide={() => onCloseInvoiceDialog()}>
                    <div className="py-3">
                        <div>{getAliasTemplate(selectedInvoice)}</div>

                        {selectedInvoice.attributes.item_type !== 'export' && selectedInvoice.attributes.item_type !== 'refund' && (
                            <>
                                <div>{paperOrElectronic(selectedInvoice)}</div>
                                <div>
                                    <div className="mt-4 mb-2">
                                        <label>Fatura Tipi</label>
                                    </div>

                                    <div className="mb-4">
                                        <Dropdown
                                            value={selectedInvoiceType}
                                            onChange={(e) => setSelectedInvoiceType(e.value)}
                                            options={invoicesType()}
                                            placeholder="Fatura Tipi Seçiniz"
                                            filter
                                            className="w-full"
                                            emptyMessage="Fatura Bulunamadı"
                                            emptyFilterMessage="Fatura Bulunamadı"
                                        />
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="mt-4">
                            <div className="mb-2">
                                <label>Sayaç Ön Ekleri</label>
                            </div>
                            <div className="mb-4">
                                <Dropdown
                                    dataKey="id"
                                    value={selectedCounter}
                                    onChange={(e) => setSelectedCounter(e.value)}
                                    options={getCounterFilter() || []}
                                    optionLabel="prefix"
                                    placeholder="Sayaç Ön Ek Seçiniz"
                                    filter
                                    className="w-full"
                                    emptyMessage="Sayaç Ön Ek Bulunamadı"
                                    emptyFilterMessage="Sayaç Ön Ek Bulunamadı"
                                />
                            </div>
                        </div>
                        <div className="justify-content-end flex mt-5">
                            <Button icon="pi pi-check" label="Güncelle" onClick={() => setUpdateInvoiceButton()} />
                        </div>
                    </div>
                </Dialog>
            )}

            <VatWithholdingDialog visible={vatWithholdingDialog} onCloseDialog={() => setVatWithholdingDialog(false)} selectedInvoiceUnit={selectedInvoiceUnit} onSetVatWithholding={setVatWithholdingButton} />

            <VatDialog visible={vatDialog} onCloseDialog={() => onCloseVatDialog()} invoiceUnitAttributes={selectedInvoiceUnit?.attributes} onSetTax={setTaxButton} />

            <ViewInvoiceDialog visible={invoiceViewDialog} invoiceView={invoiceView} onCloseDialog={() => onCloseInvoiceViewDialog()} />

            <LogDialog visible={logDialog} companyCode={selectedCompanyCode} onCloseDialog={() => setLogDialog(false)} toast={toast} />

            <SendDialog visible={sendDialog} onCloseDialog={() => setSendDialog(false)} selectedReceiptLength={selectedReceipt?.length} onSendReceipts={sendReceipts} />
        </div>
    );
}
